const serviceData = [
  {
      requestForm: "User Access Management",
      category: "User Access Management",
      service: "Identity and Access",
      types: [
          {
              name: "User Access",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": true
              },
              subcategories: ["Add", "Remove"]
          },
          {
              name: "VPN Access",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": true
              },
              subcategories: ["Add", "Remove"]
          },
          {
              name: "Role Access",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": true
              },
              subcategories: ["Add", "Remove"]
          },
          {
              name: "Group Access",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": true
              },
              subcategories: ["Add", "Remove"]
          },
          {
              name: "MFA reset",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": true
              },
              subcategories: ["Update"]
          }
      ]
  },
  {
      requestForm: "Service Modification",
      category: "Provisioning",
      service: "Cloud Services and Infrastructure",
      types: [
          {
              name: "New Account Request",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": true
              },
              subcategories: ["Add"]
          },
          {
              name: "Decommission Account Request",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": true
              },
              subcategories: ["Remove"]
          },
      ]
  },
  {
      requestForm: "Service Modification",
      category: "Modifications",
      service: "Cloud Services and Infrastructure",
      types: [
          {
              name: "Security",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": false,
                  "Self-Managed": false
              },
              subcategories: ["Update"]
          },
          {
              name: "IaaS Cloud Infrastructure",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": false,
                  "Self-Managed": false
              },
              subcategories: ["Update"]
          },
          {
              name: "PaaS",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": false
              },
              subcategories: ["Update"]
          },
          {
              name: "Networking",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": false
              },
              subcategories: ["Update"]
          },
          {
              name: "Patch Management",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": false,
                  "Self-Managed": false
              },
              subcategories: ["Update"]
          }
      ]
  },
  {
      requestForm: "Service Modification",
      category: "Tooling",
      service: "Cloud Services and Infrastructure",
      types: [
          {
              name: "Onboard / off board Tooling",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": false
              },
              subcategories: ["Add", "Remove"]
          },
          {
              name: "User Access",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": false
              },
              subcategories: ["Add", "Remove"]
          }
      ]
  },
  {
      requestForm: "Advisory Request",
      service: "Security and Governance",
      category: "Billing",
      types: [
          {
              name: "Billing Support",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": true
              },
              subcategories: ["Informational"]
          }
      ]
  },
  {
      requestForm: "Advisory Request",
      category: "Optimisation",
      service: "Cloud Services and Infrastructure",
      types: [
          {
              name: "One-off Cost Optimisation Review",
              chargingState: "Chargeable",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": false
              },
              subcategories: ["Informational"]
          },
          {
              name: "One-off Architectural Review",
              chargingState: "Chargeable",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self-Managed": false
              },
              subcategories: ["Informational"]
          }
      ]
  },
    {
        requestForm: "Advisory Request",
        category: "Automation",
        service: "Security and Governance",
        types: [
            {
                name: "Automation Development Request",
                chargingState: "Chargeable",
                appliesTo: {
                    "Fully Managed": true,
                    "Co-Managed": true,
                    "Self-Managed": false
                },
                subcategories: ["Informational"]
            }
        ]
    },
    {
        requestForm: "Advisory Request",
        category: "Governance Risk and Compliance",
        service: "Security and Governance",
        types: [
            {
                name: "Guardrail Exception Request (Layer 0)",
                chargingState: "No charge",
                appliesTo: {
                    "Fully Managed": true,
                    "Co-Managed": true,
                    "Self-Managed": false
                },
                subcategories: ["Security"]
            },
            {
                name: "Guardrail Exception Request (Layer 1)",
                chargingState: "No charge",
                appliesTo: {
                    "Fully Managed": true,
                    "Co-Managed": true,
                    "Self-Managed": false
                },
                subcategories: ["Security"]
            },
            {
                name: "Vulnerability Exception Request",
                chargingState: "No charge",
                appliesTo: {
                    "Fully Managed": true,
                    "Co-Managed": false,
                    "Self-Managed": false
                },
                subcategories: ["Security"]
            }
        ] 
    }
];

export default serviceData;
