import {
  CInputCheckbox,
  CModal,
  CModalFooter,
  CModalHeader,
  CModalTitle
} from "@coreui/react"
import Close from "assets/icons/modalClose.svg"
import { FormActions } from "core/modules/Service/components/ServiceRequest"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import styled from "styled-components"

const TermsAndConditionsBodyText = styled.p`
  hyphens: auto;
  text-align: justify;
  line-height: 1.5;
`

const TermsAndConditionsBody = styled.div`
  height: 300px;
  overflow-y: auto;
  ${TermsAndConditionsBodyText}:not(:last-child) {
    margin-bottom: 0.5rem !important;
  }
`

export default function SandboxTermsAndCondition({
  show,
  setShow,
  setSelect,
  resetStates,
  serviceProvider
}) {
  const scrollRef = useRef(null)
  const [hasRead, setHasRead] = useState(false)
  const [hasCheckedDataConsent, setHasCheckedDataConsent] = useState(false)
  const [hasCheckedTerminate, setHasCheckedTerminate] = useState(false)
  const [hasCheckedTermsAndConditions, setHasCheckedTermsAndConditions] = useState(false)

  const handleScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1
    if (bottom) {
      setHasRead(true)
    }
  }

  const handleClose = () => {
    resetStates();
    setHasRead(false)
    setHasCheckedDataConsent(false)
    setHasCheckedTerminate(false)
    setHasCheckedTermsAndConditions(false)
    setShow(false)
  }

  const handleAccept = () => {
    setSelect(true)
    setShow(false)
  }

  const handleButtonDisable = () => {
    if (!hasRead) {
      return true
    }

    if (!hasCheckedDataConsent) {
      return true
    }

    if (!hasCheckedTerminate) {
      return true
    }

    if (!hasCheckedTermsAndConditions) {
      return true
    }

    return false
  }

  const getTermsAndConditionsHeader = () => {
    switch (serviceProvider) {
      case "AWS":
        return (
          <CModalHeader className="bg-body-color">
            <CModalTitle>
              Terms and Conditions - AWS Sandbox Account Request
            </CModalTitle>
            <img
              src={Close}
              alt="close-icon"
              className="cursor-pointer close-icon"
              onClick={handleClose}
            />
          </CModalHeader>

        )
      case "AZURE":
        return (
          <CModalHeader className="bg-body-color">
            <CModalTitle>
              Terms and Conditions - Azure Sandbox Subscription Request
            </CModalTitle>
            <img
              src={Close}
              alt="close-icon"
              className="cursor-pointer close-icon"
              onClick={handleClose}
            />
          </CModalHeader>
        )

      case "GCP":
        return (
          <CModalHeader className="bg-body-color">
            <CModalTitle>
              Terms and Conditions - GCP Sandbox Project Request
            </CModalTitle>
            <img
              src={Close}
              alt="close-icon"
              className="cursor-pointer close-icon"
              onClick={handleClose}
            />
          </CModalHeader>
        )
    }
  }

  const getTermsAndConditionsBody = () => {
    switch (serviceProvider) {
      case "AWS":
        return (
          <TermsAndConditionsBody
            ref={scrollRef}
            onScroll={handleScroll}
            className="py-2 px-5"
          >
            <TermsAndConditionsBodyText>
              <strong>1. Duration:</strong> Sandbox accounts are active for a period of 90 days
              only and cannot be extended under any circumstances. The account
              will be terminated automatically by programmatic means.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
            <strong>2. Resource Requirement:</strong> In case the resources are required beyond
              90 days, the account owner must request a non-production account
              at least two weeks prior to termination. The account owner is
              responsible for migrating all workloads to the new account.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
            <strong>3. Account Management and Security:</strong> The account owner is
              responsible and fully accountable for the management and security
              of all Cloud resources, including the mitigation of any security
              threats.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
            <strong>4. Confidential Information:</strong> Sandbox accounts must not contain any
              sensitive, client, production, or confidential data in any form.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
            <strong>5. Guardrails:</strong> Users are not permitted to modify or remove default
              firm guardrails installed as part of the account, such as
              centralised logging.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
            <strong>6. Identity and Access Management (IAM):</strong> Account owners and
              sponsoring Partner/ Directors are responsible and accountable for
              ensuring that all users with Sandbox account access possess the
              necessary skills to properly manage the account.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
            <strong>7. Responsibility/Accountability for Account Activities:</strong> Account
              owners and sponsoring Partner/ Director are responsible and
              accountable for all activities in the AWS Account.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
            <strong>8. Access:</strong> Only Deloitte employees, contractors, and vendors are
              allowed console access to the accounts. Non-Deloitte employees
              must have a Deloitte user account to connect, which must be
              requested from IT Services.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
            <strong>9. Cost Responsibility:</strong> Account owners and sponsoring
              Partner/Director are responsible for all Cloud provider and
              third-party costs required to support the account, including any
              unplanned costs incurred due to user errors or lack of knowledge.
              The expected baseline cost is $20 per month for mandated security
              tooling + any further Cloud spend you incur. All cloud services are recharged at list price.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
            <strong>10. Services Provided and Support Tickets:</strong> Cloud Managed Services
              (CMS) provides this account on a self-managed basis (the account
              is managed and supported by the account owner). The services
              provided by CMS are outlined in the AWS Sandbox account support
              plan. Additional services can be requested through the CMS Service
              Desk and can be found here. Any support calls or tickets to the
              CMS Service Desk regarding the Sandbox account will be charged at
              a standard cost of $100 per ticket. AWS support should be used
              within the account for any general guidance in the first instance,
              or users should reference the{" "}
              <a
                href="https://docs.opencloudportal.io/sandboxes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation portal
              </a>
              .
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>IMPORTANT:</strong> Use of OpenAI or any Generative AI technology within the sandbox is subject to strict controls and dedicated terms. Please ensure you are familiar with, and abide by the terms of the firm's{" "}
              <a
                href="https://resources.deloitte.com/sites/dr-uk/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fdr%2Duk%2FShared%20Documents%2FRisk%2FDBS%2FDeloitteBusinessSecurity%2Fgenerative%2Dai%2Daws%2Dbedrock%2Dguardrails%2Dfor%2Dutilisation%20of%2Dthe%2Denterprise%2D%20agreement%2Epdf&parent=%2Fsites%2Fdr%2Duk%2FShared%20Documents%2FRisk%2FDBS%2FDeloitteBusinessSecurity"
                target="_blank"
                rel="noopener noreferrer"
              >
                AWS Guardrails
              </a>
              .
            </TermsAndConditionsBodyText>
          </TermsAndConditionsBody>
        )
      case "AZURE":
        return (
          <TermsAndConditionsBody
            ref={scrollRef}
            onScroll={handleScroll}
            className="py-2 px-5"
          >
            <TermsAndConditionsBodyText>
              <strong>1. Duration:</strong> Sandbox subscriptions are active for
              a period of 90 days only and cannot be extended under any
              circumstances. The subscription will be terminated automatically
              by programmatic means.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>2. Resource Requirement:</strong> In case the resources
              are required beyond 90 days, the subscription owner must request a
              non-production subscription at least two weeks prior to
              termination. The subscription owner is responsible for migrating
              all workloads to the new subscription.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>3. Account Management and Security:</strong> The
              subscription owner is responsible and fully accountable for the
              management and security of all Cloud resources, including the
              mitigation of any security threats.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>4. Confidential Information:</strong> Sandbox
              subscriptions must not contain any sensitive, client, production,
              or confidential data in any form.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>5. Guardrails:</strong> Users are not permitted to modify
              or remove default firm guardrails installed as part of the
              subscription, such as centralised logging.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>6. Identity and Access Management (IAM):</strong>{" "}
              Subscription owners and sponsoring Partner/ Directors are
              responsible and accountable for ensuring that all users with
              Sandbox subscription access possess the necessary skills to
              properly manage the subscription.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>
                7. Responsibility/Accountability for Account Activities:
              </strong>{" "}
              Subscription owners and sponsoring Partner/ Director are
              responsible and accountable for all activities in the Azure
              Subscription.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>8. Access:</strong> Only Deloitte employees, contractors,
              and vendors are allowed console access to the subscription.
              Non-Deloitte employees must have a Deloitte user account to
              connect, which must be requested from IT Services.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>9. Cost Responsibility:</strong> Subscription owners and
              sponsoring Partner/Director are responsible for all Cloud provider
              and third-party costs required to support the subscription,
              including any unplanned costs incurred due to user errors or lack
              of knowledge. The expected baseline cost is $20 per month for
              mandated security tooling + any further Cloud spend you incur. All
              cloud services are recharged at list price.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>10. Services Provided and Support Tickets:</strong> Cloud
              Managed Services (CMS) provides this subscription on a
              self-managed basis (the subscription is managed and supported by
              the subscription owner). The services provided by CMS are outlined
              in the Azure Sandbox subscription support plan. Additional
              services can be requested through the CMS Service Desk and can be
              found here. Any support calls or tickets to the CMS Service Desk
              regarding the Sandbox subscription will be charged at a standard
              cost of $100 per ticket. Azure support should be used within the
              subscription for any general guidance in the first instance, or
              users should reference the{" "}
              <a
                href="https://docs.opencloudportal.io/sandboxes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation portal
              </a>
              .
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>IMPORTANT:</strong> Use of OpenAI or any Generative AI
              technology within the sandbox is subject to strict controls and
              dedicated terms. Please ensure you are familiar with, and abide by
              the terms of the firm's{" "}
              <a
                href="https://resources.deloitte.com/sites/dr-uk/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fdr%2Duk%2FShared%20Documents%2FRisk%2FDBS%2FDeloitteBusinessSecurity%2Fazure%2Dopen%2Dai%2Dsandbox%2Dguidance%2Epdf&parent=%2Fsites%2Fdr%2Duk%2FShared%20Documents%2FRisk%2FDBS%2FDeloitteBusinessSecurity"
                target="_blank"
                rel="noopener noreferrer"
              >
                Azure Guardrails
              </a>
              .
            </TermsAndConditionsBodyText>
          </TermsAndConditionsBody>
        )

      case "GCP":
        return (
          <TermsAndConditionsBody
            ref={scrollRef}
            onScroll={handleScroll}
            className="py-2 px-5"
          >
            <TermsAndConditionsBodyText>
              <strong>IMPORTANT:</strong> Use of Vertex or any Generative AI
              technology within the sandbox is subject to strict controls and
              dedicated terms. Please ensure you are familiar with, and abide by
              the terms of the firms{" "}
              <a
                href="https://resources.deloitte.com/sites/dr-uk/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fdr%2Duk%2FShared%20Documents%2FRisk%2FDBS%2FDeloitteBusinessSecurity%2Fuk%2Dgoogle%2Dvertex%2Dai%2Dguardrails%2Epdf&parent=%2Fsites%2Fdr%2Duk%2FShared%20Documents%2FRisk%2FDBS%2FDeloitteBusinessSecurity"
                target="_blank"
                rel="noreferrer"
              >
                Vertex usage Guardrails
              </a>
              .
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>1. Duration:</strong> Sandbox projects are active for a
              period of 90 days only and cannot be extended under any
              circumstances. The project will be terminated automatically by
              programmatic means.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>2. Resource Requirement:</strong> In case the resources
              are required beyond 90 days, the project owner must request a
              non-production project at least two weeks prior to termination.
              The project owner is responsible for migrating all workloads to
              the new project.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>3. Account Management and Security:</strong> The project
              owner is responsible and fully accountable for the management and
              security of all Cloud resources, including the mitigation of any
              security threats.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>4. Confidential Information:</strong> Sandbox projects
              must not contain any sensitive, client, production, or
              confidential data in any form.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>5. Guardrails:</strong> Users are not permitted to modify
              or remove default firm guardrails installed as part of the
              project, such as centralised logging.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>6. Identity and Access Management (IAM):</strong> Project
              owners and sponsoring Partner/ Directors are responsible and
              accountable for ensuring that all users with Sandbox project
              access possess the necessary skills to properly manage the
              project.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>
                7. Responsibility/Accountability for Account Activities:
              </strong>{" "}
              Project owners and sponsoring Partner/ Director are responsible
              and accountable for all activities in the GCP Project.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>8. Access:</strong> Only Deloitte employees, contractors,
              and vendors are allowed console access to the projects.
              Non-Deloitte employees must have a Deloitte user account to
              connect, which must be requested from IT Services.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>9. Cost Responsibility:</strong> Project owners and
              sponsoring Partner/Director are responsible for all Cloud provider
              and third-party costs required to support the project, including
              any unplanned costs incurred due to user errors or lack of
              knowledge. The expected baseline cost is $20 per month for
              mandated security tooling + any further Cloud spend you incur. All
              cloud services are recharged at list price.
            </TermsAndConditionsBodyText>
            <TermsAndConditionsBodyText>
              <strong>10. Services Provided and Support Tickets:</strong> Cloud
              Managed Services (CMS) provides this project on a self-managed
              basis (the project is managed and supported by the project owner).
              The services provided by CMS are outlined in the GCP Sandbox
              Project support plan. Additional services can be requested through
              the CMS Service Desk and can be found here. Any support calls or
              tickets to the CMS Service Desk regarding the Sandbox project will
              be charged at a standard cost of $100 per ticket. GCP support
              should be used within the project for any general guidance in the
              first instance, or users should reference the{" "}
              <a
                href="https://docs.opencloudportal.io/sandboxes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation portal
              </a>
              .
            </TermsAndConditionsBodyText>
          </TermsAndConditionsBody>
        )
    }
  }

  const getTermsAndConditionsFooter = () => {
    switch (serviceProvider) {
      case "AWS":
        return (
          <CModalFooter className="bg-body-color flex-column">
            <div className="py-2 px-5 align-self-start">
              <div className="pb-1">
                <CInputCheckbox
                  checked={hasCheckedDataConsent}
                  disabled={!hasRead}
                  onChange={() => {
                    setHasCheckedDataConsent(!hasCheckedDataConsent)
                  }}
                />
                <p style={{ color: "#111" }}>
                  I confirm that no Deloitte or Client data will be used within
                  this account and that all data should be considered public.
                </p>
              </div>
              <div className="pb-1">
                <CInputCheckbox
                  checked={hasCheckedTerminate}
                  disabled={!hasRead}
                  onChange={() => {
                    setHasCheckedTerminate(!hasCheckedTerminate)
                  }}
                />
                <p style={{ color: "#111" }}>
                  I confirm that I understand this account will automatically
                  terminate after 90 days.
                </p>
              </div>
              <div>
                <CInputCheckbox
                  checked={hasCheckedTermsAndConditions}
                  disabled={!hasRead}
                  onChange={() => {
                    setHasCheckedTermsAndConditions(!hasCheckedTermsAndConditions)
                  }}
                />
                <p style={{ color: "#111" }}>
                  I confirm that I have read and will abide by the Terms &
                  Conditions set by Deloitte.
                </p>
              </div>
            </div>
            <div className="align-self-end">
              <FormActions
                onSubmit={handleAccept}
                onCancel={() => {
                  handleClose()
                }}
                isSubmitDisabled={handleButtonDisable()}
                cancelBtnStyling="mr-4"
                submitText="Accept"
                cancelText="Decline"
              />
            </div>
          </CModalFooter>
        )
      case "AZURE":
        return (
          <CModalFooter className="bg-body-color flex-column">
            <div className="py-2 px-5 align-self-start">
              <div className="pb-1">
                <CInputCheckbox
                  checked={hasCheckedDataConsent}
                  disabled={!hasRead}
                  onChange={() => {
                    setHasCheckedDataConsent(!hasCheckedDataConsent)
                  }}
                />
                <p style={{ color: "#111" }}>
                  I confirm that no Deloitte or Client data will be used within
                  this subscription and that all data should be considered public.
                </p>
              </div>
              <div className="pb-1">
                <CInputCheckbox
                  checked={hasCheckedTerminate}
                  disabled={!hasRead}
                  onChange={() => {
                    setHasCheckedTerminate(!hasCheckedTerminate)
                  }}
                />
                <p style={{ color: "#111" }}>
                  I confirm that I understand this subscription will automatically
                  terminate after 90 days.
                </p>
              </div>
              <div>
                <CInputCheckbox
                  checked={hasCheckedTermsAndConditions}
                  disabled={!hasRead}
                  onChange={() => {
                    setHasCheckedTermsAndConditions(!hasCheckedTermsAndConditions)
                  }}
                />
                <p style={{ color: "#111" }}>
                  I confirm that I have read and will abide by the Terms &
                  Conditions set by Deloitte.
                </p>
              </div>
            </div>
            <div className="align-self-end">
              <FormActions
                onSubmit={handleAccept}
                onCancel={() => {
                  handleClose()
                }}
                isSubmitDisabled={handleButtonDisable()}
                cancelBtnStyling="mr-4"
                submitText="Accept"
                cancelText="Decline"
              />
            </div>
          </CModalFooter>
        )

      case "GCP":
        return (
          <CModalFooter className="bg-body-color flex-column">
            <div className="py-2 px-5 align-self-start">
              <div className="pb-1">
                <CInputCheckbox
                  checked={hasCheckedDataConsent}
                  disabled={!hasRead}
                  onChange={() => {
                    setHasCheckedDataConsent(!hasCheckedDataConsent)
                  }}
                />
                <p style={{ color: "#111" }}>
                  I confirm that no Deloitte or Client data will be used within
                  this project and that all data should be considered public.
                </p>
              </div>
              <div className="pb-1">
                <CInputCheckbox
                  checked={hasCheckedTerminate}
                  disabled={!hasRead}
                  onChange={() => {
                    setHasCheckedTerminate(!hasCheckedTerminate)
                  }}
                />
                <p style={{ color: "#111" }}>
                  I confirm that I understand this project will automatically
                  terminate after 90 days.
                </p>
              </div>
              <div>
                <CInputCheckbox
                  checked={hasCheckedTermsAndConditions}
                  disabled={!hasRead}
                  onChange={() => {
                    setHasCheckedTermsAndConditions(!hasCheckedTermsAndConditions)
                  }}
                />
                <p style={{ color: "#111" }}>
                  I confirm that I have read and will abide by the Terms &
                  Conditions set by Deloitte.
                </p>
              </div>
            </div>
            <div className="align-self-end">
              <FormActions
                onSubmit={handleAccept}
                onCancel={() => {
                  handleClose()
                }}
                isSubmitDisabled={handleButtonDisable()}
                cancelBtnStyling="mr-4"
                submitText="Accept"
                cancelText="Decline"
              />
            </div>
          </CModalFooter>
        )
    }
  }


  return (
    show && (
      <CModal
        show={show}
        onClose={handleClose}
        className="incident-modal pt-3"
        size="lg"
        fade={false}
      >
        {getTermsAndConditionsHeader()}
        {getTermsAndConditionsBody()}
        {getTermsAndConditionsFooter()}
      </CModal>
    )
  )
}

SandboxTermsAndCondition.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setSelect: PropTypes.func,
  setServiceProvider: PropTypes.func,
  resetStates: PropTypes.func,
  subHeading: PropTypes.string
}
